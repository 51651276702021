/**
 * Page for the conclusion of the pre-screener.
 */

import React from 'react';
import { Link, navigate } from 'gatsby';
import Layout from '../components/PageLayout';
import PageHead from '../components/PageHead';
import Safe from '../components/Safe';
import { localizer } from '../util/localizer';
import { css } from '../util/pagetools';
import logo from '../images/logo.png';

export default function ThankYou(props) {
  const ctx = props.pageContext;
  const localized = localizer(ctx);
  const backHome = localized('/');
  const cfg = (props.location ? (props.location.state || {}) : {});
  const isBrowser = typeof window !== 'undefined';

  // Redirect to home page if page is accessed without proper parameters
  if (Object.keys(cfg).length===0) {
    if (isBrowser) navigate(backHome);
    return null;
  }

  const sitedata = cfg.sitedata;
  let adImgTracker = null;
  if (isBrowser && window.threewireConsent && props.useAds) {
    pageEvents(cfg.isQualified);
    adImgTracker = <img height="1" width="1" style={{borderStyle:'none'}} alt="TO-BE-REPLACED" src="https://localhost:8000/favicon.ico" />;
  }

  const headerPart = (
    <div className='nav-bar-thank-you'>
      <div className="navbar-logo-container">
        <Link to={backHome}>
          <img src={logo} alt={sitedata.sitename} className="nav-bar-logo" />
        </Link>
      </div>
    </div>
    );

  return (
    <Layout config={sitedata} ctx={ctx} currentpath={props.path} header={headerPart}>
      <PageHead lang={ctx.locale} sitename={sitedata.sitename} siteurl={sitedata.siteurl} meta={sitedata.metaDescription} />
      <div className={css('thank-you', cfg.theme)}>
        <Safe className="thank-you-h1" type="h1" content={cfg.header} />
        {cfg.text.map((item, index) => <Safe type="p" key={`p-${index}`} content={item} />)}
        <Link to={backHome} className={css('thank-you-btn', cfg.btntheme)}>{sitedata.homelink}</Link>
      </div>
      {isBrowser && window.threewireConsent && props.useAds && adImgTracker}
    </Layout>
  );
};

// Run tracking tag features
function pageEvents(isQualified) {
  if (window.gtag) {
    window.gtag('event', 'conversion', { 'send_to': 'AW-10945197917/9ij-COSq98wDEN3uieMo' });
    window.gtag('event', 'form_submitted', { 'event_category': 'Screener', 'event_label': `Form submitted ${(isQualified ? 'Q' : 'DQ')}` });
  }
}
