/**
 * PageLayout is a page wrapper providing a specified or default header
 * and a footer.
 * Supported props: config ctx currentpath header theme fpOn fpOff
 */

import React from 'react';
import Nav from './NavToggle';
import FixedPanel from './FixedPanel';
import { CookieProvider } from './CookieContext';
import Footer from './FooterSimple';
import { setLinks } from '../util/pagetools';
import { localizer } from '../util/localizer';

export default function PageLayout(props) {
  const cfg = props.config;
  const localized = localizer(props.ctx);
  const toplinks = setLinks(cfg.sections);
  const footdata = {
    ...cfg.footer,
    toplinks,
    sitename: cfg.sitename,
    siteurl: cfg.siteurl
  };
  const mainstyle = (props.theme ? {className: props.theme} : {});
  const defaultHeader = (
    <>
      <Nav toplinks={toplinks}
           sitename={cfg.sitename}
           ctx={props.ctx}
           currentpath={props.currentpath}
           localizerfn={localized}
      />
      {props.fpOn && <FixedPanel src={cfg.fixedpanel} btnLabel={cfg.startstudy} visibleAt={props.fpOn} hideAt={props.fpOff} />}
    </>
  );

  return (
    <div id="page">
      <CookieProvider content={cfg.cookie}>
        <div id="layout-root">
          <header>{props.header || defaultHeader}</header>
          <main {...mainstyle}>{props.children}</main>
          <Footer src={footdata} localizerfn={localized} />
        </div>
      </CookieProvider>
    </div>
  );
};
